.team {
    @extend %of-hidden;
    background-color: $primary-color;

    &__item {
        @extend %of-hidden;
        @include add-prefix(transition, $transition);
        text-align: center;
        padding: 20px;

        @include hover {
            @include add-prefix(transform, translateY(-5px));
        }
    }

    &__thumb {
        @extend %mb-20;

        img {
            border-radius: 4px;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        h4 {
            a {
                color: $white-color;
            }
        }

        p {
            color: $white-color;
        }
    }
}