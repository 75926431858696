.about {
    @extend %of-hidden;

    &__thumb {
        @extend %text-center;

        img {
            animation: light 4s ease-in-out infinite alternate;
        }
    }

    &__content {
        h2 {
            @extend %mb-30;
        }

        p {
            @include font-size(18px);
        }
    }
}