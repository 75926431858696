.banner {
    @extend %of-hidden;
    padding-block: 75px;
    background: linear-gradient(180deg, rgba(23, 24, 53, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);

    @include breakpoint(lg) {
        padding-block: 120px;
    }

    &__content {
        @extend %mb-50;
        text-align: center;

        @include breakpoint(lg) {
            margin-bottom: 80px;
        }

        h1 {
            @extend %mb-20;
            text-transform: capitalize;


            @include breakpoint(xxl) {
                max-inline-size: 90%;
                margin-left: auto;
                margin-right: auto;
            }

        }

        h1,
        p {
            color: $white-color;
        }

        p {
            @extend %mb-45;
            @include font-size(18px);
            line-height: 1.65;
        }
    }

    &__images {
        &-list {
            @extend %mb-0;
            @extend %justify-center;
            display: flex;
            align-items: flex-end;

        }

        &-item {
            @include add-prefix(transition, $transition);

            &:hover {
                @include add-prefix(transform, translateY(-10px));
            }
        }
    }
}